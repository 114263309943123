@font-face {
    font-family: 'jaysalvat';
    src:url('../fonts/jaysalvat.eot');
    src:url('../fonts/jaysalvat.woff') format('woff'),
        url('../fonts/jaysalvat.ttf') format('truetype'),
        url('../fonts/jaysalvat.svg#jaysalvat') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    font-family: "jaysalvat";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-codepen:before {
  content: "";
}

.icon-email:before {
  content: "";
}

.icon-jaysalvat:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-left:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-mouse:before {
  content: "";
}

.icon-orientation:before {
  content: "";
}

.icon-right:before {
  content: "";
}

.icon-touch:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}
