$header-timing: 0.30;
$header-keywords: 6;

.header {
    position: relative;
    background: #000;
    color: #fff;
    background: url(/img/background-header.jpg) center 20% no-repeat;
    background-size: cover;

    .touch & {
        background-position: bottom left;
    }
}

.header-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    transition: background-color 5s;

    .animated & {
        background-color: rgba(0,0,0,0.8);
    }
}

.header-bio {
    position: absolute;
    left: 50%;
    top: 42%;
    width: 100%;
    text-align: center;
    font-weight: 300;
    margin-top: -150px;
    margin-left: -50%;

    .no-touch & {
        top: 42%;
    }

    a {
        transition: 0.5s;

        &:hover {
            color: $color;
        }
    }

    h2 {
        font-size: 2em;
        font-weight: 300;
        word-spacing: -0.2em;
        margin-bottom: 0.2em;
    }

    p strong {
        position: relative;
        color: #fff;
        opacity: 0;
        transition: opacity 1s;
    }

    @for $i from 1 through $header-keywords {
        .header.animated & p strong:nth-of-type(#{$i}) {
            opacity: 1;
            transition-delay: #{$i * $header-timing}s;
        }
    }

    p span {
        color: #eee;
        opacity: 0;
        transition: opacity 3s;
    }

    .header.animated & span {
        opacity: 1;
        transition-delay: #{$header-keywords * $header-timing}s;
    }
}

.header-avatar {
    width: 6.5em;
    height: 6.5em;
    margin-bottom: 0.5em;
    border: 0.15em solid #fff;
    border-radius: 100%;
    // box-shadow: 0 10px 30px #000;

    @media (min-width: $screen-large) {
        width: 4.8em;
        height: 4.8em;
    }
}

$header-social-delay: ($header-keywords + 2) * $header-timing;

.social {
    margin: 0.8em auto 0;

    ul {
        height: 3.5em;
    }

    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        float: left;
        margin: 5px;
    }

    a {
        position: absolute;
        left: 50%;
        width: 3.2em;
        height: 3.2em;
        margin-left: -1.5em;
        display: block;
        text-decoration: none;
        border: 0.12em solid #fff;
        border-radius: 50%;
        overflow: hidden;

        opacity: 0;
        transition: opacity 1s, transform 0.7s ease-out, border-color 1s;
        transition-delay: ($header-social-delay) * 1s, ($header-social-delay + $header-timing) * 1s;

        &:active {
            border: 10px solid $color;
        }

        @media only screen and (min-device-width: 480px) {
            &:hover {
                transition-delay: 0, 0, 0;
                border-color: $color;

                .icon {
                    transition-delay: 0, 0, 0;
                    color: $color;
                }
            }
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3.6em;
        height: 1em;
        margin: -0.5em 0 0 -1.74em;
        color: #ddd;
        font-size: 1.6em;
        opacity: 0;
        transition: opacity 1s ease, color 1s;
        transition-delay: ($header-social-delay + $header-timing)s, 0;
    }

    .icon-twitter {
        font-size: 1.7em;
    }

    .icon-email {
        font-size: 1.4em;
        margin: -0.5em 0 0 -1.8em;
    }

    .icon-github {
        font-size: 1.8em;
        margin: -0.55em 0 0 -1.78em;
    }

    .icon-codepen {
        margin: -0.5em 0 0 -1.78em;
    }
}

.header.animated {
    .icon {
        opacity: 1;
    }

    a {
        opacity: 1;
    }

    .social-email a {
        transform: translateX(-180%);
    }

    .social-codepen a {
        transform: translateX(-60%);
    }

    .social-github a {
        transform: translateX(60%);
    }

    .social-twitter a {
        transform: translateX(180%);
    }
}

.header-scroll {
    .touch & {
        display: none;
    }

    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 100px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    opacity: 0;
    text-decoration: none;

    transform: translateX(-50%);
    transition: opacity 1s;
    transition-delay: 3s;

    .header.animated & {
        opacity: 0.7;
    }

    .icon {
        padding: 10px;
        display: block;
        font-size: 3em;
        animation: flash 2.5s ease-out infinite;
    }
}

@keyframes flash {
    0%   {
        opacity: 0.3;
        transform: translateY(0px);
    }
    50%  {
        opacity: 1;
        transform: translateY(3px);
    }
    100% {
        opacity: 0.3;
        transform: translateY(0px);
    }
}
