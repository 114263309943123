$footer-timing: 0.1;
$footer-keywords: 12;

.footer {
    position: relative;
    background: #000;
    color: #fff;
    // background: url(/img/background-footer.jpg) center 20% no-repeat;
    background: url(/img/background-header.jpg) center 20% no-repeat;
    background-size: cover;
    display: table;
    padding: 0;

    h2 {
        font-size: 1.5em;
        line-height: 1.1em;
        word-spacing: -0.2em;
        margin-bottom: 0.6em;
    }

    p {
        margin-bottom: 1.2em;
    }

    p span {
        position: relative;
        color: #fff;
        opacity: 0;
        transition: opacity 1s;
    }

    @for $i from 1 through $footer-keywords {
        &.animated p span:nth-of-type(#{$i}) {
            opacity: 1;
            transition-delay: #{$i * $footer-timing}s;
        }
    }

    p strong {
        color: #eee;
        opacity: 0;
        transition: opacity 4s;
    }

    &.animated strong {
        opacity: 1;
        transition-delay: #{($footer-keywords) * $footer-timing}s;
    }
}

.footer-container {
    display: table-cell;
    vertical-align: middle;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.8);
}

   .cube-container {
        display: block;
        width: 200px;
        height: 100px;
        perspective: 1000px;
        margin: -15px auto 15px;
        position: relative;
        opacity: 0;
        transition: opacity 1s;
        transition-delay: 3s;

        .animated & {
            opacity: 1;
        }
    }

    .cube {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        transition: 0.25s all ease-out;
        transform-style: preserve-3d;
    }

    .cube div {
        display: block;
        position: absolute;
        width: 200px;
        height: 100px;
        opacity: 0;
        transition: 0.25s all ease-out;

        &.active {
            opacity: 1;
        }
    }

    .cube img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    .cube .front  {
        transform: translateZ( 50px );
    }
    .cube .back   {
        transform: rotateX( -180deg ) translateZ( 50px );
    }
    .cube .top {
        transform: rotateX(   90deg ) translateZ( 50px );
    }
    .cube .bottom {
        transform: rotateX(  -90deg ) translateZ( 50px );
    }
