$typing-color: #2196F3; // #B2FF59; // #B2FF59;

.bubble {
    background: #eee;
    border-radius: 5px;
    width: 250px;
    height: 33px;
    position: absolute;
    top: -55px;
    left: 50%;
    margin-left: -125px;
    padding: 10px;
    font: 12px "Courier", sans-serif;
    text-align: left;
    color: #000;
    opacity: 0;
    animation: glowing alternate infinite 1s;
    transform: translateY(25px);
    transition: .5s 0s all ease-in-out;

    @media (min-width: $screen-medium) {
        margin-left: -20px;
        top: -70px;
    }

    .animated &.shown {
        transition: .5s 4s all ease-in-out;
        transform: translateY(0);
        opacity: 1;
    }
}

    .bubble-arrow {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 12px solid #eee;
        position: absolute;
        bottom: -5px;
        left: 80px;
        transform: rotate(-20deg);

        @media (min-width: $screen-medium) {
            bottom: -8px;
            left: 33px;
            transform: rotate(20deg);
        }
    }

    .bubble-cursor {
        display: inline-block;
        background: $typing-color;
        color: #fff !important;
    }

    .bubble-blinking {
        animation: 1s blinking step-end infinite;
        margin-left: 2px;
    }

@keyframes "glowing" {
    from {
        box-shadow: 0 0 30px $typing-color;
    }
    to {
        box-shadow: 0 0 0px $typing-color;
    }
}

@keyframes "blinking" {
    from, to {
        background: $typing-color;
    }
    50% {
        background: transparent;
    }
}