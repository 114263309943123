*, *:after, *:before {
    box-sizing: border-box;
    backface-visibility: hidden;
}

html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
}

body {
    background: #000;
    font: 300 0.9em/1.7 Raleway;
    transition: transform 1s;
    
    @media (min-width: $screen-medium) {
        font-size: 1.2em;
    }

    @media (min-width: $screen-large) {
        font-size: 1.1em;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 800;
}

strong {
    font-weight: 800;
}

a {
    color: inherit;
}
