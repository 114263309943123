$color:          #DBA901; // #FFC107; // #D5F271;
$screen-small:     480px;
$screen-medium:    768px;
$screen-large:     960px;
$screen-xlarge:   1200px;

@import "_reset";
@import "_fonts";
@import "_base";
@import "_icons";
@import "_slides";
@import "_header";
@import "_works";
@import "_footer";
@import "_bubble";
