.slide-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform;
    transform: translateZ(0);
    transition: transform 1s ease;
}

.slide {
    width: 100vw;
    height: 100vh;
    padding: 20px;
    text-align: center;
    color: #fff;
    will-change: transform;
    
    // transform: translateZ(0) scale(0.9);
    // opacity: 0;
    // transition: transform 1s, opacity 1s;
    // &.active {
    //     opacity: 1;
    //     transform: scale(1);
    // }
}

.slide-swipe {
    .not-touch & {
        display: none;
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    opacity: 0;
    z-index: -10;
    transition: opacity 1s, z-index 1s;
    transition-delay: 2s 3s;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        color: white;
        font-size: 4em;
        text-shadow: 0 0 5px #000;
    }

    &.orientation,
    &.vertical,
    &.horizontal {
        opacity: 1;
        z-index: 99999;
    }

    &.vertical .icon-touch {
        animation: swipe-vertical 0.85s ease 1s 2;
    }

    &.horizontal .icon-touch {
        animation: swipe-horizontal 0.85s ease 1s 2;
    }

    &.orientation {
        background: rgba(0,0,0,0.9);

        .icon-orientation {
            opacity: 1;
            font-size: 6em;
        }
    }
}

@keyframes swipe-vertical {
    0%   {
        opacity: 0;
        transform: translateZ(0) translateY(25vh) rotate(40deg);
    }
    50%  {
        opacity: 1;
        transform: translateZ(0) translateY(-25vh) rotate(20deg);
    }
    100% {
        opacity: 0;
        transform: translateZ(0) translateY(25vh) rotate(40deg);
    }
}

@keyframes swipe-horizontal {
    0%   {
        opacity: 0;
        transform: translateZ(0) translateX(25vw) rotate(40deg);
    }
    50%  {
        opacity: 1;
        transform: translateZ(0) translateX(-25vw) rotate(0deg);
    }
    100% {
        opacity: 0;
        transform: translateZ(0) translateX(25vw) rotate(40deg);
    }
}

