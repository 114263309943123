.works {
    position: relative;
    padding: 0;
}

.work-container {
    position: relative;
    display: table;
    width: 1000vw;
    height: 100vh;
    background: linear-gradient(#444, #000);
    transform: translateZ(0);
    transition: transform 0.5s ease;
}

.work {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: table-cell;
    float: left;
    box-shadow: 0 0 20px #000;
    overflow: hidden;

    @media (max-device-width: $screen-medium + 1) {
        transition: transform 1s ease, opacity 1s;
        transform: scale(0.9);
        opacity: 0;

        &.active {
            opacity: 1;
            transform: scale(1);
        }
    }

    @media (min-width: $screen-large) {
        width: 33.333vw;
    }

    @media (min-width: $screen-xlarge) {
        width: 25vw;
    }
}

.work-pagination {
    position: absolute;
    left: 50%;
    bottom: 15vh;
    color: #fff;
    margin: 0;
    padding: 0;
    transform: translateX(-50%);

    @media (max-device-width: $screen-medium) and (orientation: landscape) {
        display: none;
    }

    a {
        width: 10px;
        height: 10px;
        margin: 5px;
        display: block;
        border: 0.1em solid #fff;
        border-radius: 50%;
        display: inline-block;
        transition: transform 0.5s;

        &.active {
            background: #fff;
            transform: scale(1.3);
        }
    }
}

.work-previous,
.work-next {
    position: absolute;
    top: 47%;
    margin-top: 130px;
    display: block;
    font-size: 2em;
    text-decoration: none;
    text-shadow: 0 0 5px rgba(#000, 0.5);
    transition: transform 1s;
    transform: translate(0, -50%);
}

    .work-previous {
        left: 30px;

        &.disabled {
            opacity: 0;
            transform: translate(-50px, 0);
        }

        &:not(.disabled) .icon {
            animation: flash-left 4s ease-out infinite;
        }
    }

    .work-next {
        right: 30px;

        &.disabled {
            opacity: 0;
            transform: translate(50px, 0);
        }

        &:not(.disabled) .icon {
            animation: flash-right 4s ease-out infinite;
        }
    }

.work-pagination a,
.work-button {
    box-shadow:  0 0 5px rgba(#000, 0.3);
    text-shadow: 0 0 5px rgba(#000, 0.5);
}

/* Boxes */

.work-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent,rgba(#000, 0.6));
    transform: translateY(100vh);
    transition: transform 0.25s ease;

    @media (min-width: $screen-medium) and (min-device-width: $screen-medium + 1) {
        .work:hover & {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.work-logo,
.work-details,
.work-button {
    position: absolute;
    left: 50%;
    top: 44%;
    transform: translate(-50%, -50%);
    transition: all 2s cubic-bezier(0,1,0,1);
}

    .work-logo {
        display: block;
        height: auto;

        @media (max-width: $screen-medium) {
            top: 18%;
        }

        @media (min-width: $screen-medium) and (min-device-width: $screen-medium + 1) {
            .work:hover & {
                opacity: 0.03;
                transform: translate(-50%, -50%) scale(2.2);
            }
        }
    }

    .work-details {
        width: 100%;
        display: table;
        padding: 10px;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0 1px 1px rgba(#000, 1);

        @media (min-width: $screen-medium) and (min-device-width: $screen-medium + 1) {
            opacity: 0;

            .work:hover & {
                opacity: 1;
                transform: translate(-50%, -90px);
            }
        }

        .work-wrapper {
            display: table-cell;
            vertical-align: middle;
            height: 6em;
        }

        h3 {
            font-size: 1.5em;
            line-height: 1.1em;
            padding-bottom: 0.4em;
        }

        p {
            font-size: 0.9em;
            line-height: 1.1em;
        }
    }

    .work-button {
        width: 5em;
        height: 5em;
        border: 0.2em solid white;
        border-radius: 50%;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        font-weight: bold;
        color: #fff;
        line-height: 4.6em;

        @media (max-width: $screen-medium) {
            top: 66%;
        }

        @media (min-width: $screen-medium) and (min-device-width: $screen-medium + 1) {
            opacity: 0;
            width: 4em;
            height: 4em;
            line-height: 3.8em;

            .work:hover & {
                opacity: 1;
                transform: translate(-50%, 40px);
            }
        }

        &:hover {
            border-color: rgba(#fff, 0.2);
            transform: translate(-50%, 30px) scale(1.2) !important;
        }
    }

/* Projects */

.facedetection {
    background: url(/img/facedetection-bg.jpg) center center repeat;
}
    .facedetection-logo {
        opacity: 0.9;
        width: 80%;
        max-width: 450px;
    }

.vegas {
    position: relative;
    background: #000 url(/img/vegas-bg1.jpg) no-repeat center center;
    background-size: cover;

    .vegas-logo {
        width: 95%;
        max-width: 450px;
    }

    @media (min-width: $screen-medium) and (min-device-width: $screen-medium + 1) {
        transition: .3s background-position ease;
        background-position: 50% center;

        &:hover {
            background-position: 60% center;
        }
    }
}
    .vegas-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(/img/vegas-overlay.png), linear-gradient(transparent, rgba(#000,  0.4));
        opacity: .3;
    }

.buzz {
    background: #7FA917;
    background: linear-gradient(#7FA917, darken(#7FA917, 18));
}
    .buzz-logo {
        width: 50%;
        max-width: 300px;
    }

.markitup {
    // overflow: hidden;
    background: #aaa;
    background: linear-gradient(#fff, #888);
}
    .markitup-logo {
        width: 180%;
        max-width: 700px;
    }

@keyframes flash-left {
    0%   {
        opacity: 0.4;
        transform: translateX(0px);
    }
    50%  {
        opacity: 1;
        transform: translateX(-10px) scale(1.6);
    }
    100% {
        opacity: 0.4;
        transform: translateX(0px);
    }
}

@keyframes flash-right {
    0%   {
        opacity: 0.4;
        transform: translateX(0px);
    }
    50%  {
        opacity: 1;
        transform: translateX(10px) scale(1.6);
    }
    100% {
        opacity: 0.4;
        transform: translateX(0px);
    }
}
